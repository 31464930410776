import { HeadingStyle } from '../includes/heading-style';
import cx from 'classnames';
import { HandleWYSIWYGContent, HandleAnimations } from '../functions/helper';
import { YouTubeVideo } from '@dx-ui/osc-youtube-video';
import type { StyleObject } from '../functions/global-instance-styles';
import { GIS_merge, GIS_TextAlignment } from '../functions/global-instance-styles';
import type { WordpressPageInfoQuery } from '@dx-ui/queries-dx-curated-ui/generated/wp';
import type { ACFAnchorProps } from '../global/anchor-link';
import { AnchorLink } from '../global/anchor-link';
import { CroppedImage, customLoader } from '../cropped-image/cropped-image';
import { appliedCloudinaryParams } from '@curated-property/utils';
import type { PageProps } from '../../../../../../apps/dx-curated-ui';

export function videoMediaCopyPropMapper(
  data: VideoMediaCopyProps & {
    componentSettingsVideoMediaCopySettings?: StyleObject;
  },
  global: NonNullable<
    NonNullable<WordpressPageInfoQuery['componentStyles']>['globalComponentSettings']
  >['globalComponentSettings'],
  enableHHR: NonNullable<PageProps>['wpThemeSettings']['themeSettings']['SettingsThemeSettings']['useHhrBrandTheme']
) {
  return {
    heading: data?.heading,
    copy: data?.copy,
    buttonsButtons: data?.buttonsButtons,
    videoTitle: data?.videoTitle,
    videoMediaUrl: data?.videoMediaUrl,
    globalStyles: global?.videoMediaCopySettings,
    largeImage: data?.largeImage,
    smallImage: data?.smallImage,
    instanceStyles: data?.componentSettingsVideoMediaCopySettings,
    imageLargeCropHeight: data.imageLargeCropHeight,
    imageLargeCropWidth: data.imageLargeCropWidth,
    imageLargeEnableCropping: data.imageLargeEnableCropping,
    imageLargeCropType: data.imageLargeCropType,
    imageLargeXPosition: data.imageLargeXPosition,
    imageLargeYPosition: data.imageLargeYPosition,
    imageLargeXPositionAdvanced: data.imageLargeXPositionAdvanced,
    imageLargeYPositionAdvanced: data.imageLargeYPositionAdvanced,
    imageSmallCropHeight: data.imageSmallCropHeight,
    imageSmallCropWidth: data.imageSmallCropWidth,
    imageSmallEnableCropping: data.imageSmallEnableCropping,
    imageSmallCropType: data.imageSmallCropType,
    imageSmallXPosition: data.imageSmallXPosition,
    imageSmallYPosition: data.imageSmallYPosition,
    imageSmallXPositionAdvanced: data.imageSmallXPositionAdvanced,
    imageSmallYPositionAdvanced: data.imageSmallYPositionAdvanced,
    enableHHR,
  };
}

interface ImgProps {
  sourceUrl?: string;
  altText?: string;
}
export interface VideoMediaCopyProps {
  heading?: string;
  videoMediaUrl?: string;
  videoTitle?: string;
  copy?: string;
  componentInstance?: number;
  buttonsButtons?: Array<ACFAnchorProps>;
  largeImage?: ImgProps;
  smallImage?: ImgProps;
  imageLargeCropHeight?: string;
  imageLargeCropWidth?: string;
  imageLargeEnableCropping?: boolean;
  imageLargeCropType?: string;
  imageLargeXPosition?: string;
  imageLargeYPosition?: string;
  imageLargeXPositionAdvanced?: string;
  imageLargeYPositionAdvanced?: string;
  imageSmallCropHeight?: string;
  imageSmallCropWidth?: string;
  imageSmallEnableCropping?: boolean;
  imageSmallCropType?: string;
  imageSmallXPosition?: string;
  imageSmallYPosition?: string;
  imageSmallXPositionAdvanced?: string;
  imageSmallYPositionAdvanced?: string;
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
  enableHHR?: boolean;
}

export function VideoMediaCopy({
  heading,
  copy,
  componentInstance,
  buttonsButtons,
  videoMediaUrl,
  videoTitle,
  largeImage,
  smallImage,
  imageLargeCropHeight,
  imageLargeCropWidth,
  imageLargeEnableCropping,
  imageLargeCropType,
  imageLargeXPosition,
  imageLargeYPosition,
  imageLargeXPositionAdvanced,
  imageLargeYPositionAdvanced,
  imageSmallCropHeight,
  imageSmallCropWidth,
  imageSmallEnableCropping,
  imageSmallCropType,
  imageSmallXPosition,
  imageSmallYPosition,
  imageSmallXPositionAdvanced,
  imageSmallYPositionAdvanced,
  globalStyles,
  instanceStyles,
  enableHHR,
}: VideoMediaCopyProps) {
  const inlineStyles = GIS_merge(globalStyles, instanceStyles);
  const textAlignment = GIS_TextAlignment(inlineStyles?.textAlignment);
  // Change button array const
  const buttons = buttonsButtons;

  //assign unique id to youtube player instance
  const youtubeVideoId = `youtubeVideo-${componentInstance}`;

  const animations = HandleAnimations({
    hideAnimation: inlineStyles?.hideAnimations !== 'show',
    start: inlineStyles?.animationDirection
      ? `lg:${inlineStyles?.animationDirection}-8`
      : inlineStyles?.horizontalDirection === 'reverse'
      ? 'lg:translate-x-8'
      : 'lg:-translate-x-8',
    delayOne: 'delay-200',
    delayTwo: 'delay-300',
    delayThree: 'delay-500',
    delayFour: 'delay-700',
    delayFive: 'delay-900',
  });

  // Check that 2 of 2 possible images have been added.
  const showImages = largeImage?.sourceUrl && smallImage?.sourceUrl ? true : false;

  const imgWidth = 320;
  const imgHeightShort = 268;
  const imgHeightTall = 392;
  const imgHeightExtraTall = 420;

  const upperImage = (
    <CroppedImage
      id="upperImage"
      className={cx('w-full, h-auto')}
      loader={() => {
        return customLoader({
          src: smallImage?.sourceUrl || '',
          width: `${imgWidth}`,
          height: `${imgHeightShort}`,
          crop: imageSmallEnableCropping || false,
          cropType: imageSmallCropType || '',
          cropHeight: imageSmallCropHeight || '',
          cropWidth: imageSmallCropWidth || '',
          xPosition: imageSmallXPosition || '',
          xPositionAdvanced: imageSmallXPositionAdvanced || '',
          yPosition: imageSmallYPosition || '',
          yPositionAdvanced: imageSmallYPositionAdvanced || '',
        });
      }}
      src={smallImage?.sourceUrl || ''}
      width={imgWidth}
      height={imgHeightShort}
      alt={smallImage?.altText}
      objectFit="fill"
    />
  );

  const lowerImageDesktop = (
    <CroppedImage
      className={cx('h-auto w-full')}
      loader={() => {
        return customLoader({
          src: largeImage?.sourceUrl || '',
          width: `${imgWidth}`,
          height: `${imgHeightTall}`,
          crop: imageLargeEnableCropping || false,
          cropType: imageLargeCropType || '',
          cropHeight: imageLargeCropHeight || '',
          cropWidth: imageLargeCropWidth || '',
          xPosition: imageLargeXPosition || '',
          xPositionAdvanced: imageLargeXPositionAdvanced || '',
          yPosition: imageLargeYPosition || '',
          yPositionAdvanced: imageLargeYPositionAdvanced || '',
        });
      }}
      src={largeImage?.sourceUrl || ''}
      width={imgWidth}
      height={imgHeightTall}
      alt={largeImage?.altText}
      objectFit="fill"
    />
  );

  // For the lg: breakpoint
  const lowerImageExtraTallDesktop = (
    <CroppedImage
      className={cx('h-auto w-full')}
      loader={() => {
        return customLoader({
          src: largeImage?.sourceUrl || '',
          width: `${imgWidth}`,
          height: `${imgHeightExtraTall}`,
          crop: imageLargeEnableCropping || false,
          cropType: imageLargeCropType || '',
          cropHeight: imageLargeCropHeight || '',
          cropWidth: imageLargeCropWidth || '',
          xPosition: imageLargeXPosition || '',
          xPositionAdvanced: imageLargeXPositionAdvanced || '',
          yPosition: imageLargeYPosition || '',
          yPositionAdvanced: imageLargeYPositionAdvanced || '',
        });
      }}
      src={largeImage?.sourceUrl || ''}
      width={imgWidth}
      height={imgHeightExtraTall}
      alt={largeImage?.altText}
      objectFit="fill"
    />
  );

  const lowerImageMobile = (
    <CroppedImage
      className={cx('w-full')}
      loader={() => {
        return customLoader({
          src: largeImage?.sourceUrl || '',
          width: `${imgWidth}`,
          height: `${imgHeightShort}`,
          crop: imageLargeEnableCropping || false,
          cropType: imageLargeCropType || '',
          cropHeight: imageSmallCropHeight || '',
          cropWidth: imageSmallCropWidth || '',
          xPosition: imageLargeXPosition || '',
          xPositionAdvanced: imageLargeXPositionAdvanced || '',
          yPosition: imageLargeYPosition || '',
          yPositionAdvanced: imageLargeYPositionAdvanced || '',
        });
      }}
      src={largeImage?.sourceUrl || ''}
      width={imgWidth}
      height={imgHeightShort}
      alt={largeImage?.altText}
      objectFit="fill"
    />
  );

  let ctaAlignment;

  switch (textAlignment) {
    case 'left':
      ctaAlignment = 'justify-start';
      break;
    case 'center':
      ctaAlignment = 'justify-center';
      break;
    case 'right':
      ctaAlignment = 'justify-end';
      break;
    default:
      ctaAlignment = 'justify-start';
  }

  const buttonSettingDefault = enableHHR ? 'primary' : 'primary-outline';

  return (
    <div
      ref={animations?.ref}
      data-testid="video-media-copy"
      data-element-id="video-media-copy-component"
      style={{
        backgroundImage: inlineStyles?.componentBackgroundImage?.sourceUrl
          ? `url('${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage?.sourceUrl,
              inlineStyles?.componentBackgroundRepeat
            )}')`
          : undefined,
        backgroundColor: inlineStyles?.componentBackgroundColor || undefined,
        backgroundSize: inlineStyles?.componentBackgroundSize || undefined,
        backgroundRepeat: inlineStyles?.componentBackgroundRepeat || 'no-repeat',
        backgroundPosition: inlineStyles?.componentBackgroundPosition || 'top left',
      }}
    >
      <div className="py-8 lg:py-32">
        <div
          data-element-id="video-media-copy-content"
          className={cx('bg-bg-alt w-full py-4')}
          style={{
            backgroundImage: inlineStyles?.contentBackgroundImage?.sourceUrl
              ? `url('${appliedCloudinaryParams(
                  inlineStyles?.contentBackgroundImage?.sourceUrl,
                  inlineStyles?.contentBackgroundRepeat
                )}')`
              : undefined,
            backgroundColor: inlineStyles?.contentBackgroundColor || undefined,

            alignItems: inlineStyles?.textAlignment,
            backgroundSize: inlineStyles?.contentBackgroundSize || undefined,
            backgroundRepeat: inlineStyles?.contentBackgroundRepeat || 'no-repeat',
            backgroundPosition: inlineStyles?.contentBackgroundPosition || 'left center',
          }}
        >
          <div
            className={cx('container', !textAlignment ? `text-left` : `text-${textAlignment}`)}
            style={{
              maxWidth: inlineStyles?.containerMaxWidth && `${inlineStyles?.containerMaxWidth}px`,
            }}
          >
            {heading && (
              <HeadingStyle
                text={heading}
                textColorInline={inlineStyles?.titleColor}
                type="h2"
                className={cx(
                  'font-headline block  py-6 text-2xl font-bold lg:hidden',
                  !textAlignment ? `text-left` : `text-${textAlignment}`,
                  animations?.one
                )}
              />
            )}
            <div
              className={cx(
                'flex h-auto w-full flex-col-reverse items-center py-0 lg:w-11/12 lg:flex-row xl:w-full',
                inlineStyles?.horizontalDirection === 'reverse' && 'lg:flex-row-reverse'
              )}
              id="contentArea"
            >
              <div
                className={cx(
                  'w-full p-6 lg:w-1/2',
                  inlineStyles?.containerMaxWidth && !showImages
                    ? 'lg:pl-12 lg:pr-0 xl:px-16 xl:py-24'
                    : 'lg:px-16 lg:py-24'
                )}
                id="copyContent"
              >
                {
                  // Larger screen headline
                  heading && (
                    <HeadingStyle
                      textColorInline={inlineStyles?.titleColor}
                      text={heading}
                      type="h2"
                      className={cx(
                        'font-headline mb-4 hidden text-2xl font-bold lg:block',
                        animations?.one
                      )}
                    />
                  )
                }
                {copy && (
                  <div
                    data-testid="video-media-copy-block"
                    style={{ color: inlineStyles?.textColor }}
                    className={cx('py-2 text-lg', animations?.two)}
                    dangerouslySetInnerHTML={{
                      __html: HandleWYSIWYGContent(copy),
                    }}
                  />
                )}
                {buttons?.length > 0 && (
                  <div
                    data-testid="video-component-cta-wrapper"
                    className={cx(`mt-4 flex flex-wrap`, ctaAlignment, animations?.three)}
                  >
                    {buttons?.map((link, key) => {
                      if (!link || !link.link || !link.link?.url) {
                        return null;
                      }
                      return (
                        <AnchorLink
                          dataTestId="video-media-copy-cta"
                          key={key}
                          url={link.link?.url || ''}
                          title={link.link?.title || ''}
                          target={link.link?.target || ''}
                          buttonStyle={link?.buttonStyle ?? buttonSettingDefault}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
              <div
                className={cx(
                  'w-full md:w-auto lg:w-1/2 ',
                  'mx-auto  flex lg:-my-24 lg:flex lg:flex-row lg:justify-center',

                  showImages
                    ? `flex-col-reverse md:flex md:flex-row md:justify-center`
                    : `flex-row md:flex md:flex-row md:justify-center`
                )}
                id="videoPhotoContent"
              >
                {showImages && (
                  <div
                    data-testid="img-section"
                    id="imgSection"
                    className={cx(
                      'items-between flex w-full flex-row justify-between p-0 md:w-[45%] md:flex-col md:pr-4 lg:w-2/5 lg:pr-0 xl:w-[45%] xl:pr-4',
                      animations?.four
                    )}
                  >
                    <div
                      data-element-id="upper-image-section"
                      className={cx('w-full pr-2 md:p-0')}
                      data-testid="upper-image"
                    >
                      {upperImage}
                    </div>

                    <div
                      data-element-id="lower-image-section"
                      className={cx('m-0 w-full pl-4 md:translate-y-1 md:p-0')}
                      data-testid="lower-image"
                    >
                      <div
                        data-element-id="lower-image-small-device"
                        className={cx('block md:hidden')}
                        data-testid="lower-image-mobile"
                      >
                        {lowerImageMobile}
                      </div>
                      <div
                        data-element-id="lower-image-desktop"
                        className={cx('hidden md:block md:pt-8 lg:hidden lg:pt-0 xl:block')}
                        data-testid="lower-image-regular-desktop"
                      >
                        {lowerImageDesktop}
                      </div>
                      <div
                        data-element-id="lower-image-desktop-taller"
                        className={cx('hidden md:pt-8 lg:block lg:pt-0 xl:hidden')}
                        data-testid="lower-image-tall-desktop"
                      >
                        {lowerImageExtraTallDesktop}
                      </div>
                    </div>
                  </div>
                )}

                <div
                  id="videoWrapper"
                  data-testid="video-section"
                  className={cx(
                    'relative  mx-auto  w-full pl-0 md:pb-0 lg:pl-4',
                    showImages ? `pb-8 md:w-1/2 lg:w-[55%]` : `flex flex-row justify-center pb-0`,
                    showImages ? animations?.five : animations?.four
                  )}
                >
                  {videoMediaUrl && (
                    <YouTubeVideo
                      wrapperClassName={cx(
                        'absolute top-0 h-full w-auto',
                        !showImages && `w-full lg:w-1/2`
                      )}
                      id={youtubeVideoId}
                      isVertical
                      source="youtube"
                      title={videoTitle}
                      url={videoMediaUrl}
                      isAutoLoop={false}
                      isAutoPlay={false}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
