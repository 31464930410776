import { parse, startOfToday, format, addDays } from 'date-fns';
import { iconmapper } from '../../functions/helper';
import type { MergedRoom } from '../buildFilters';
import type { Hotel_ShopAvailOptions_ShopPropAvailQuery } from '@dx-ui/queries-dx-curated-ui/generated/dx-gql-rooms';

export type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;

export interface GuestsState {
  adults: number;
  kids: number;
  kidsAges: number[];
}

export function formatDate(date: Date) {
  return format(date, 'yyyy-MM-dd');
}

export type Icon =
  | {
      id?: string;
      name?: string;
    }
  | string
  | undefined;

export function getIcon(icon: Icon) {
  const iconMap = iconmapper();
  if (typeof icon === 'string') {
    return {
      Icon: iconMap[icon],
      name: icon,
    };
  } else
    return {
      Icon: iconMap[icon?.id] || (() => null),
      name: icon?.name,
    };
}

export function removeCtyhocnPrefixFromRoomCode(roomCode: string) {
  if (roomCode.includes('-')) {
    return roomCode.split('-').pop();
  } else {
    return roomCode;
  }
}

export function parseDate(date: string) {
  return parse(date, 'yyyy-MM-dd', startOfToday());
}

export function parseTomorrow(date: string, add?: number) {
  return addDays(parse(date, 'yyyy-MM-dd', startOfToday()), add || 0);
}

/**
 *
 * Match up room types from avail with roomTypes passed via roomTypeCode
 */
export function mergeRooms({
  rooms,
  availRooms,
}: {
  availRooms?: NonNullable<
    NonNullable<Hotel_ShopAvailOptions_ShopPropAvailQuery['hotel']>['shopAvail']
  >['roomTypes'];
  rooms: MergedRoom[];
}) {
  const roomsByRoomTypeCode = rooms?.reduce((prev, curr) => {
    if (!curr.roomTypeCode) {
      return prev;
    }
    prev[curr.roomTypeCode] = curr;
    return prev;
  }, {} as Record<string, MergedRoom>);

  const availByRoomTypeCode = availRooms?.reduce((prev, curr) => {
    if (!curr.roomTypeCode) {
      return prev;
    }
    prev[curr.roomTypeCode] = curr;
    return prev;
  }, {} as Record<string, ArrayElement<typeof availRooms>>);

  return rooms?.map((r) => {
    const roomCode = r?.roomTypeCode;
    if (roomCode?.includes('-')) {
      const duplicateRoomCode = roomCode?.split('-');
      for (let i = 0; i < availRooms?.length; i++) {
        if (
          //@ts-expect-error - ctyhocn doesnt exist on the shopavailoptions query but is needed here
          duplicateRoomCode[0] === availRooms[i]?.ctyhocn &&
          duplicateRoomCode[1] === availRooms[i]?.roomTypeCode
        ) {
          return {
            ...roomsByRoomTypeCode?.[r?.roomTypeCode || ''],
            ...availRooms[i],
            ...r,
          };
        }
      }
      return {
        ...roomsByRoomTypeCode?.[r?.roomTypeCode || ''],
        ...availByRoomTypeCode?.[r?.roomTypeCode || ''],
        ...r,
      };
    } else {
      return {
        ...roomsByRoomTypeCode?.[r?.roomTypeCode || ''],
        ...availByRoomTypeCode?.[r?.roomTypeCode || ''],
        ...r,
      };
    }
  });
}

export function guestsPerRoom(guests: number, age = null) {
  const arr = [];
  Array.from({ length: guests }).forEach((__, key) => {
    if (age === null) {
      arr.push({});
    } else {
      arr.push({ age: age[key] });
    }
  });
  return arr;
}

export function carouselImageURL(images, size) {
  if (images) {
    for (let i = 0; i < images.length; i++) {
      if (images[i].size === size) {
        return images[i].url;
      }
    }
  } else {
    return 'https://assets.hiltonstatic.com/images/v1583957952/no-image/no-image.png';
  }
}

export function parseRoomFilterQueryParams(param: string | string[]) {
  const v = param instanceof Array ? param : param?.split(',') || [];
  const b = v?.map((i) => (Number(i) ? parseInt(i) : i));
  return b;
}
