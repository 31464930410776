/* eslint-disable complexity */
import { useReducer, useRef, useState, useContext, useEffect } from 'react';
import {
  appliedCloudinaryParams,
  parseBookingDate,
  SharedContext,
  makeRandomInt,
  getMinLengthOfStay,
} from '@curated-property/utils';
import type { StyleObject } from '../functions/global-instance-styles';
import { GIS_TextAlignment, GIS_Padder, GIS_merge } from '../functions/global-instance-styles';
import * as Tabs from '@radix-ui/react-tabs';
import { FilterTags } from './filters/filter-flyout';
import { useQuery } from '@tanstack/react-query';
import cx from 'classnames';
import { addDays } from 'date-fns';
import type { Filters, MergedRoom } from './buildFilters';
import { useTranslation } from 'next-i18next';
import { HandleAnimations, hexToRgb } from '../functions/helper';
import { useRouter } from 'next/router';
import { useLocation } from '@dx-ui/framework-location-provider';
import { Information, Bath, Bed, KidFriendly, Resort } from '@curated-property/icon-list';
import { getAvailQuery } from '@dx-ui/queries-dx-curated-ui/queries/dx-gql-rooms/dx-gql';
import { FilterFlyoutDisplay } from './filters/filter-flyout-display';
import { FindYourBestRoom } from './filters/fybr-filters';
import { GuestsPanel, CheckBoxPanel, TubCustomCheckboxPanels } from './filters/fybr-filter-panels';
import type { GuestsState } from './utils';
import { formatDate, mergeRooms, parseRoomFilterQueryParams } from './utils';
import { RoomCard } from './includes/room-card';
import { FilterTab } from './filters/filter-panel-tab';
import { FilterPanel } from './filters/filter-panel';
import type { RoomsCTAProps } from './includes/rooms-cta';
import { FormProvider, useForm } from 'react-hook-form';
import { DatePicker } from '@dx-ui/osc-date-picker';
import { useIsClient } from 'usehooks-ts';

export interface RoomTypesProps {
  rooms: MergedRoom[];
  filters: Filters;
  pricingEnabled: boolean;
  allowAdultsOnly?: boolean;
  hideLeadRates?: boolean;
  hideBedsFilter?: boolean;
  hideMaxGuestsFilter?: boolean;
  hidePriceFilter?: boolean;
  arrivalDate?: string;
  gmtHours?: number;
  pricingDisclaimer?: string;
  sortRoomsBy?: string;
  ctyhocn: string;
  associatedHotels?: string[];
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
  customFilters?: CustomFilters;
  resEnabled?: boolean;
  showFindYourBestRoomFilters?: boolean;
}

export interface CustomFilters {
  pageTitle?: string;
  SettingsRoomSettings?: {
    roomFilters1Title?: string;
    roomFilters2Title?: string;
    roomFilters3Title?: string;
  };
}

function usePrevious(data: any) {
  const ref = useRef();
  if (!data) {
    return ref.current;
  }
  ref.current = data;
  return data;
}

const iconData = ({ room, squareFootageText }: { room: MergedRoom; squareFootageText: string }) => [
  room?.sleeps?.[0]
    ? {
        id: 'user',
        name: room?.sleeps?.[0]?.name,
      }
    : undefined,
  (room?.squareFootage || 0) > 0
    ? {
        id: room?.squareFootageIconGroup?.iconList_noTx || 'roomPlan',
        name: squareFootageText,
      }
    : undefined,
  room?.balconyIconGroup?.iconList
    ? {
        id: room?.balconyIconGroup?.iconList_noTx,
        name: room?.customBalcony || room?.balconyDetail,
      }
    : room?.balconyDetail_noTx === 'Other' && room?.customBalcony_noTx
    ? {
        id: room?.customBalcony_noTx,
        name: room?.customBalcony,
      }
    : room?.balconyDetail &&
      room?.balconyDetail_noTx !== 'none' &&
      room?.balconyDetail_noTx !== 'Other'
    ? {
        id: 'balcony',
        name: room?.balconyDetail,
      }
    : undefined,
  room?.viewIconGroup?.iconList
    ? {
        id: room?.viewIconGroup?.iconList_noTx,
        name: room?.customView || room?.view,
      }
    : room?.view_noTx === 'Other' && room?.customView
    ? {
        id: room?.customView_noTx,
        name: room?.customView,
      }
    : room?.view && room?.view_noTx !== 'none' && room?.view_noTx !== 'Other'
    ? {
        id:
          room?.view_noTx === 'Ocean Front' || room?.view_noTx === 'Partial Ocean'
            ? 'oceanView'
            : room?.view_noTx,
        name: room?.view,
      }
    : undefined,
];

export function RoomTypes({
  rooms,
  pricingEnabled,
  allowAdultsOnly,
  hideLeadRates,
  hideBedsFilter,
  hideMaxGuestsFilter,
  hidePriceFilter,
  filters,
  gmtHours,
  arrivalDate,
  pricingDisclaimer,
  sortRoomsBy,
  ctyhocn,
  associatedHotels,
  globalStyles,
  instanceStyles,
  customFilters,
  resEnabled,
  showFindYourBestRoomFilters,
}: RoomTypesProps) {
  const sharedContext = useContext(SharedContext);

  const fybrTab = 'filter-fybr';
  const featuresTab = 'filter-features';
  const [selectedTab, setSelectedTab] = useState(fybrTab);
  const [sortState, setSortState] = useState('');
  const [showerState, setShowerState] = useState(false);
  const startDate = new Date(parseBookingDate(gmtHours, arrivalDate));
  const isClient = useIsClient();
  const methods = useForm<{
    dates: { arrivalDate: Date | undefined; departureDate: Date | undefined };
  }>({
    defaultValues: {
      dates: {
        arrivalDate: startDate,
        departureDate: addDays(startDate, getMinLengthOfStay(ctyhocn)),
      },
    },
  });
  const { watch } = methods;
  const watchDates = watch('dates');
  const dates = {
    arrival: formatDate(watchDates.arrivalDate),
    departure: watchDates.departureDate ? formatDate(watchDates.departureDate) : undefined,
  };

  const [guests, setGuests] = useState<GuestsState>({
    adults: sharedContext?.allInclusive ? 2 : 1,
    kids: 0,
    kidsAges: [],
  });
  const [t] = useTranslation();
  const { query, locale: routerLocale = 'en' } = useRouter();
  const location = useLocation();

  let firstHotel, otherHotelRooms;
  const ctyhocns = associatedHotels?.length > 0 ? associatedHotels : [ctyhocn];

  function CorePlusQuery(ctyhocn: string) {
    // Please note that 'US' is used as a default country as a means of allowing rates through in localhost.
    const queryResult = useQuery({
      queryKey: [
        getAvailQuery,
        {
          ctyhocn,
          arrivalDate: dates.arrival,
          departureDate: dates.departure,
          language: 'en',
          numAdults: sharedContext?.allInclusive ? 2 : 1,
          guestLocationCountry: location?.country || 'US',
        },
      ],

      enabled: pricingEnabled && !!dates.departure,
    });

    return queryResult;
  }

  function addCtyhocnToRoomTypes(rooms, ctyhocn) {
    rooms?.forEach((r) => {
      if (typeof r === 'object') {
        r.ctyhocn = ctyhocn;
      }
    });
    return rooms;
  }

  for (let i = 0; i < ctyhocns?.length; i++) {
    let query;
    try {
      query = CorePlusQuery(ctyhocns[i]);
    } catch {
      // we don't need to do anything with this error, as it's somewhat expected
    }

    if (i === 0) {
      firstHotel = query;
      addCtyhocnToRoomTypes(firstHotel?.data?.hotel?.shopAvail?.roomTypes, ctyhocns[i]);
    } else {
      otherHotelRooms =
        addCtyhocnToRoomTypes(query?.data?.hotel?.shopAvail?.roomTypes, ctyhocns[i]) || [];
      firstHotel?.data?.hotel?.shopAvail?.roomTypes.push(...otherHotelRooms);
    }
  }

  const shopAvailQuery = firstHotel;

  const { data, isError, isFetching: isLoading, status } = shopAvailQuery || {};

  const potentiallyPreviousData = usePrevious(isError ? {} : data);

  const mergedRooms = mergeRooms({
    rooms,
    availRooms: potentiallyPreviousData?.hotel?.shopAvail?.roomTypes,
  });

  const [filterState, dispatchFilterState] = useReducer(reducer, {
    balconyDetail: parseRoomFilterQueryParams(query['balconyDetail']),
    guests: parseRoomFilterQueryParams(query['guests']),
    numBeds: parseRoomFilterQueryParams(query['numBeds']),
    brandName: parseRoomFilterQueryParams(query['brandName']),
    adaAccessibleRoom: parseRoomFilterQueryParams(query['adaAccessibleRoom']),
    recommendedFor: parseRoomFilterQueryParams(query['recommendedFor']),
    premiumOptions: parseRoomFilterQueryParams(query['premiumOptions']),
    outdoorFeatures: parseRoomFilterQueryParams(query['outdoorFeatures']),
    roomFeatures: parseRoomFilterQueryParams(query['roomFeatures']),
    roomType: parseRoomFilterQueryParams(query['roomType']),
    bathroomAmenities: parseRoomFilterQueryParams(query['bathroomAmenities']),
    locations: parseRoomFilterQueryParams(query['locations']),
    customFilter1: parseRoomFilterQueryParams(query['customFilter1']),
    customFilter2: parseRoomFilterQueryParams(query['customFilter2']),
    customFilter3: parseRoomFilterQueryParams(query['customFilter3']),
    bedType: parseRoomFilterQueryParams(query['bedType']),
    view: parseRoomFilterQueryParams(query['view']),
    showerTubAmenities: parseRoomFilterQueryParams(query['showerTubAmenities']),
  });

  const filteredRooms = mergedRooms.filter((room) => {
    return Object.keys(filterState).every((key) => {
      if (filterState[key].length === 0) {
        return true;
      }
      const roomAttribute = room[key];

      if (key === 'guests') {
        return filterState[key].some((filter) => {
          if (typeof filter === 'string' && filter.includes('–')) {
            const min = parseInt(filter.split('–')[0], 10);
            return room['maxOccupancy'] >= min;
          } else {
            return parseInt(filter, 10) <= room['maxOccupancy'];
          }
        });
      } else if (key === 'showerTubAmenities') {
        return filterState[key]?.every((k) => room?.showerTubAmenities[0]?.name?.includes(k));
      } else if (key === 'customFilter1') {
        return filterState[key]?.every((k) =>
          room?.showCustomFilter1?.customFilter1Select?.includes(k)
        );
      } else if (key === 'customFilter2') {
        return filterState[key]?.every((k) =>
          room?.showCustomFilter2?.customFilter2Select?.includes(k)
        );
      } else if (key === 'customFilter3') {
        return filterState[key]?.every((k) =>
          room?.showCustomFilter3?.customFilter3Select?.includes(k)
        );
      } else if (Array.isArray(roomAttribute)) {
        return filterState[key]?.every((k) => roomAttribute.includes(k));
      } else
        return (
          filterState[key].includes(roomAttribute) ||
          filterState[key].includes(room.customView) ||
          filterState[key].includes(room.customBalcony)
        );
    });
  });

  const roomResultsCount = filteredRooms.length;

  // if any of the rooms have prices, rooms without prices are considered "sold out", or "not available"
  // vs if no rooms have prices then there was an error fetching pricing info
  const hasPrices = mergedRooms?.some((r) => !!r.moreRatesFromRate?.rateAmountFmt);

  // If dates are set but departure is undefined for some reason (closing the widget early), set it to arrival+1
  // removing this temporarily to figure out what went wrong
  // {
  //   dates &&
  //     dates?.departure === undefined &&
  //     (dates.departure = formatDate(addDays(parseDate(dates.arrival), 1)));
  // }

  const inlineStyles = GIS_merge(globalStyles, instanceStyles);
  const textAlignment = GIS_TextAlignment(inlineStyles?.textAlignment);
  const paddingStyles = GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom);

  const promoteAvailable = (a, b) => {
    if (a.moreRatesFromRate && !b.moreRatesFromRate) {
      return -1;
    } else if (!a.moreRatesFromRate && b.moreRatesFromRate) {
      return 1;
    } else return 0;
  };

  const handleSortState = (item) => {
    if (sortState === item) {
      setSortState('');
    } else setSortState(item);
  };

  let maxOccupancy;
  if (filters?.guests?.length > 0) {
    maxOccupancy = filters.guests.reduce(function (prev, current) {
      return prev && prev.value > current.value ? prev : current;
    });
  }

  // Keep values that are not null
  const filterView = filters?.view?.filter((v) => v?.value !== null);
  const filterBathroom = filters?.bathroomAmenities?.filter((v) => v?.value !== null);
  const filterShower = filters?.showerTubAmenities?.filter((v) => v?.value !== null);

  const hasFiltersForFYBR =
    filterView?.length > 0 || filterBathroom?.length > 0 || filterShower?.length > 0;

  const filterByFeatures = (
    <FilterFlyoutDisplay
      filters={filters}
      filterState={filterState}
      dispatchFilterState={dispatchFilterState}
      handleSortState={handleSortState}
      sortState={sortState}
      hasPrices={hasPrices}
      customFilters={customFilters}
      labelColor={inlineStyles?.filterTitleColor || inlineStyles?.textColor}
      hideFilters={{
        beds: hideBedsFilter,
        maxGuests: hideMaxGuestsFilter,
        price: hidePriceFilter,
      }}
    />
  );

  const handlePriceSort = (a, b): number => {
    const aNum = Number(a.moreRatesFromRate?.rateAmountFmt.replace(/\D/g, ''));
    const bNum = Number(b.moreRatesFromRate?.rateAmountFmt.replace(/\D/g, ''));

    // Default room tiles sorting to price (low - high)
    // This applies to room tiles, room tiles lite and rooms from posts
    switch (true) {
      case sortState === '>':
        return aNum > bNum ? -1 : aNum < bNum ? 1 : 0;
      case sortState === '<':
        return aNum < bNum ? -1 : aNum > bNum ? 1 : 0;
      case sortRoomsBy !== 'custom':
        return aNum < bNum ? -1 : aNum > bNum ? 1 : 0;
      default:
        return 0;
    }
  };

  const animations = HandleAnimations({
    hideAnimation: inlineStyles?.hideAnimations !== 'show',
    start: `${inlineStyles?.animationDirection ?? '-translate-x'}-8`,
    delayOne: 'delay-200',
    delayTwo: 'delay-300',
    delayThree: 'delay-500',
  });

  const styleRandomInt = makeRandomInt().toString();
  const styleIdPrefix = 'roomTypes';
  const componentStyleID = `${styleIdPrefix}${styleRandomInt}`;
  const styleElementID = `${styleIdPrefix}Style${styleRandomInt}`;
  const roomsSelector = '[data-element-id="room-types-wrapper"]';
  const dateSelector = '[data-osc-product="shop-form-dates"]';
  const iconRgb = hexToRgb(inlineStyles?.dropdownAndAccessibleCheckboxTextIcon);
  const activeIconRgb = hexToRgb(inlineStyles?.selectActiveCheckboxCheckmarkAndOutline);
  // This will be injected into <head> on an instance-by-instance bases
  let styleString = '';

  if (inlineStyles?.dateTextColor) {
    styleString += `
      .rooms-widget-wrapper > button > div > span.font-bold,
      .rooms-widget-wrapper [data-osc-product="shop-form-dates"] > span > span.text-primary {
        color: ${inlineStyles?.dateTextColor};
      }
    `;
  }

  if (inlineStyles?.dividingLineColor) {
    styleString += `
    .rooms-widget-wrapper [data-osc-product="shop-form-dates"] > span:last-of-type {
      border-color: ${inlineStyles?.dividingLineColor} !important;
    }
    `;
  }

  if (inlineStyles?.monthColor) {
    styleString += `
      ${roomsSelector} ${dateSelector} span > span > span:first-of-type {
        color: ${inlineStyles?.monthColor};
      }
    `;
  }

  if (inlineStyles?.dayOfTheWeekColor) {
    styleString += `
      ${roomsSelector} ${dateSelector} span > span > span:last-of-type {
        color: ${inlineStyles?.dayOfTheWeekColor};
      }
    `;
  }

  if (inlineStyles?.dropdownAndAccessibleCheckboxBackground) {
    styleString += `
    ${roomsSelector} input[type="checkbox"],
    input[type="radio"],
    [class*="-flyout"] input[type="checkbox"],
    [class*="-flyout"] input[type="radio"] {
      background-color: ${inlineStyles?.dropdownAndAccessibleCheckboxBackground};
    }
    ${roomsSelector} .form-select {
      background-color: ${inlineStyles?.dropdownAndAccessibleCheckboxBackground};
    }
  `;
  }

  if (inlineStyles?.dropdownAndAccessibleCheckboxOutline) {
    styleString += `
    ${roomsSelector} input[type="checkbox"],
    [class*="-flyout"] input[type="checkbox"],
    input[type="radio"],
    [class*="-flyout"] input[type="radio"] {
      border-color: ${inlineStyles?.dropdownAndAccessibleCheckboxOutline};
      outline-color: ${inlineStyles?.dropdownAndAccessibleCheckboxOutline};
    }
    ${roomsSelector} .form-select {
      border-color: ${inlineStyles?.dropdownAndAccessibleCheckboxOutline};
      outline-color: ${inlineStyles?.dropdownAndAccessibleCheckboxOutline};
    }
  `;
  }

  if (inlineStyles?.dropdownAndAccessibleCheckboxTextIcon) {
    styleString += `
    ${roomsSelector} input[type="checkbox"],
    input[type="radio"] {
      color: ${inlineStyles?.dropdownAndAccessibleCheckboxTextIcon};
    }
    ${roomsSelector} input[type="checkbox"]:checked,
    input[type="radio"]:checked,
    [class*="-flyout"] input[type="checkbox"]:checked,
    [class*="-flyout"] input[type="radio"]:checked {
      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='rgb(${iconRgb.r},${iconRgb.g},${iconRgb.b})' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
    }
    ${roomsSelector} .form-select {
      color: ${inlineStyles?.dropdownAndAccessibleCheckboxTextIcon};
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='rgb(${iconRgb?.r},${iconRgb?.g},${iconRgb?.b})'%3e%3cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3e%3c/svg%3e");
    }
  `;
  }

  if (inlineStyles?.selectActiveBackground) {
    styleString += `
    [class*="-flyout"] {
      background-color: ${inlineStyles?.selectActiveBackground};
      border-color: ${inlineStyles?.selectActiveBackground};
    }
  `;
  }

  if (inlineStyles?.selectActiveText) {
    styleString += `
    [class*="-flyout"] {
      color: ${inlineStyles?.selectActiveText};
    }
  `;
  }

  if (inlineStyles?.selectDividingLine) {
    styleString += `
    [class*="-flyout"] > div > div > div {
      border-color: ${inlineStyles?.selectDividingLine};
    }
  `;
  }

  if (inlineStyles?.selectActiveCheckboxBackground) {
    styleString += `
    [class*="-flyout"] input[type="checkbox"],
    [class*="-flyout"] input[type="radio"] {
      background: ${inlineStyles?.selectActiveCheckboxBackground};
    }
  `;
  }

  if (inlineStyles?.selectActiveCheckboxCheckmarkAndOutline) {
    styleString += `
    [class*="-flyout"] input[type="checkbox"]:checked,
    [class*="-flyout"] input[type="radio"]:checked {
      outline-color: ${inlineStyles?.selectActiveCheckboxCheckmarkAndOutline};
      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='rgb(${activeIconRgb?.r},${activeIconRgb?.g},${activeIconRgb?.b})' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
    }
  `;
  }

  if (inlineStyles?.checkboxActiveOutlineAndCheckmark || inlineStyles?.checkboxActiveBackground) {
    const checkmarkColor = inlineStyles?.checkboxActiveOutlineAndCheckmark;
    styleString += `
    ${roomsSelector} input[type="checkbox"]:checked,
    input[type="radio"]:checked,
    [class*="-flyout"] input[type="checkbox"]:checked,
    [class*="-flyout"] input[type="radio"]:checked {
      outline-color: ${inlineStyles?.checkboxActiveOutlineAndCheckmark}!important;
      background-color: ${inlineStyles?.checkboxActiveBackground}!important;
      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='rgb(${
        hexToRgb(checkmarkColor)?.r
      },${hexToRgb(checkmarkColor)?.g},${
      hexToRgb(checkmarkColor)?.b
    })' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e")!important;
    }
  `;
  }

  // Adds style to <head>
  useEffect(() => {
    const $style = document.createElement('style');
    $style.setAttribute('id', styleElementID);
    document.head.appendChild($style);
    $style.innerHTML = styleString;
  }, [styleElementID, styleString]);

  return (
    <div
      id={componentStyleID}
      data-element-id="room-types-wrapper"
      className={cx(inlineStyles?.showHide && 'hidden', 'bg-bg-alt cp-roomTypes', paddingStyles)}
      style={{
        backgroundColor: inlineStyles?.filterBackgroundColor || null,
      }}
    >
      <div ref={animations?.ref} className="container px-0">
        <div className="grid items-end justify-center px-2 md:px-4 lg:flex-col">
          {pricingEnabled && (
            <div className={cx('rooms-widget-wrapper flex justify-center', animations?.one)}>
              {isClient ? (
                <FormProvider {...methods}>
                  <DatePicker language={routerLocale} hideFlexDates={true} />
                </FormProvider>
              ) : null}
            </div>
          )}
          <div className={cx('w-full', animations?.two)}>
            {showFindYourBestRoomFilters && hasFiltersForFYBR ? (
              <Tabs.Root
                id="roomsFilterTabs"
                data-testid="filter-tabs"
                onValueChange={(value) => {
                  setSelectedTab(value);
                }}
                defaultValue={fybrTab}
              >
                <Tabs.List className="my-8 grid grid-cols-[150px_auto_150px] items-center justify-center !bg-transparent sm:grid-cols-[300px_auto_300px]">
                  <FilterTab id={0} value={fybrTab} selectedValue={selectedTab}>
                    {t('findYourBestRoom')}
                  </FilterTab>
                  <div
                    className="h-[40px] w-px bg-black"
                    style={{ backgroundColor: inlineStyles?.fybrDividerColor }}
                  />
                  <FilterTab id={1} value={featuresTab} selectedValue={selectedTab}>
                    {t('filterByFeatures')}
                  </FilterTab>
                </Tabs.List>
                <FilterPanel value={fybrTab}>
                  <div className="flex flex-wrap justify-center">
                    <FindYourBestRoom
                      styles={{
                        textColor: inlineStyles?.fybrTextColor,
                        headingColor: inlineStyles?.fybrSubheadingColor,
                        dividerColor: inlineStyles?.fybrDividerColor,
                        disabledColor: inlineStyles?.fybrDisabledColor,
                        navButtonsColor: inlineStyles?.fybrNavigationColor,
                      }}
                      dispatchFilterState={dispatchFilterState}
                      setShowerState={setShowerState}
                      filterData={{
                        guests: {
                          active: true,
                          tabTitle: t('guestsLabel'),
                          tabId: 'guests',
                          tabIcon: <KidFriendly className="mr-1 w-8" />,
                          tabPanelTitle: t('howManyGuests'),
                          component: (
                            <GuestsPanel
                              guests={guests}
                              setGuests={setGuests}
                              maxOccupancy={maxOccupancy?.value as number}
                              adultsOnly={allowAdultsOnly}
                              data={filters?.adaAccessibleRoom}
                              dispatchFilterState={dispatchFilterState}
                              filterState={filterState}
                              stepperColor={inlineStyles?.fybrStepperColor}
                              stepperColorDisabled={inlineStyles?.fybrDisabledColor}
                            />
                          ),
                        },
                        beds: {
                          active: true,
                          tabTitle: t('beds'),
                          tabId: 'beds',
                          tabIcon: <Bed className="mr-1 w-8" />,
                          tabPanelTitle: t('whichBedSize'),
                          component: (
                            <CheckBoxPanel
                              data={filters?.bedType}
                              dispatchFilterState={dispatchFilterState}
                              filterState={filterState?.bedType}
                              filterKey="bedType"
                            />
                          ),
                        },
                        view: {
                          active: filterView?.length > 0,
                          tabTitle: t('view'),
                          tabId: 'view',
                          tabIcon: <Resort className="mr-1 w-8" />,
                          tabPanelTitle: t('whichView'),
                          component: (
                            <CheckBoxPanel
                              data={filterView}
                              dispatchFilterState={dispatchFilterState}
                              filterState={filterState?.view}
                              filterKey="view"
                            />
                          ),
                        },
                        showerTub: {
                          active: filterShower?.length > 0 || filterBathroom?.length > 0,
                          tabTitle: t('showerTub'),
                          tabId: 'shower',
                          tabIcon: <Bath className="mr-1 w-8" />,
                          tabPanelTitle:
                            filterShower?.length > 0 ? t('showerOrTub') : t('bathroomAmenities'),
                          component:
                            filterShower?.length > 0 ? (
                              <TubCustomCheckboxPanels
                                data={filterShower}
                                dispatchFilterState={dispatchFilterState}
                                filterState={filterState?.showerTubAmenities}
                                filterKey="showerTubAmenities"
                                showerState={showerState}
                                setShowerState={setShowerState}
                              />
                            ) : (
                              <CheckBoxPanel
                                data={filterBathroom}
                                dispatchFilterState={dispatchFilterState}
                                filterState={filterState?.bathroomAmenities}
                                filterKey="bathroomAmenities"
                              />
                            ),
                        },
                      }}
                    />
                  </div>
                </FilterPanel>
                <FilterPanel value={featuresTab}>
                  <div className="grid grid-cols-2 flex-wrap justify-center gap-2 md:grid-cols-5 lg:flex lg:space-x-2">
                    {filterByFeatures}
                  </div>
                </FilterPanel>
              </Tabs.Root>
            ) : (
              <div className="grid grid-cols-2 flex-wrap justify-center gap-2 py-6 lg:flex lg:space-x-2">
                {filterByFeatures}
              </div>
            )}
          </div>
        </div>

        {Object.values(filterState).some((a) => a?.length) || sortState || showerState ? (
          <FilterTags
            styles={{
              filterTagButtonStyle: inlineStyles?.filterTagButtonStyle,
              clearAllButtonStyle: inlineStyles?.clearAllButtonStyle,
            }}
            className={animations?.two}
            selectedFilters={Object.keys(filterState)
              .reduce((prev, key) => {
                const selectedElements = filterState[key];
                const i = selectedElements.map((value) => {
                  return {
                    label: filters[key].find((f) => f.value === value)?.label,
                    value: filters[key],
                    remove() {
                      dispatchFilterState({
                        checked: false,
                        item: value,
                        key: key as Action['key'],
                      });
                    },
                  };
                });

                return prev.concat(i);
              }, [])
              .concat([
                {
                  label:
                    sortState === '<' ? t('lowToHigh') : sortState === '>' ? t('highToLow') : null,
                },
                {
                  label: showerState ? t('showerLabel') : null,
                },
              ])}
            clearAll={() => {
              setSortState('');
              setShowerState(false);
              dispatchFilterState({ clearAll: true });
            }}
            clearPriceSort={() => setSortState('')}
            clearShowerState={() => setShowerState(false)}
          />
        ) : null}

        <div
          data-testid="roomResults"
          data-element-id="room-results"
          className={cx('mb-5 flex justify-center border-b p-4 text-center', animations?.three)}
          style={{ borderColor: inlineStyles?.filterRuleColor || null }}
        >
          <div style={{ color: inlineStyles?.filterTitleColor || null }}>
            {t('weFoundResults', { count: roomResultsCount })}
          </div>
        </div>

        <div className={cx('mb-4 space-y-2', animations?.three)}>
          {isError && (
            <div className="bg-warn-alt px-3 py-2 text-sm">
              <Information className="mr-2 inline-block size-5" />
              {t('fetchPricingError')}
            </div>
          )}

          {hasPrices && pricingDisclaimer && (
            <div className="bg-success-alt text-success flex items-center px-3 py-2 text-sm">
              <Information className="mr-2 inline-block size-5" />
              {pricingDisclaimer}
            </div>
          )}
        </div>
        <div
          className="grid grid-cols-1 gap-5 overflow-hidden px-2 py-4 pt-2 md:grid-flow-row-dense md:grid-cols-2 md:gap-y-6 md:px-4 md:pt-4 lg:grid-cols-3 2xl:px-0"
          style={{
            backgroundImage: inlineStyles?.componentBackgroundImage
              ? `url('${appliedCloudinaryParams(
                  inlineStyles?.componentBackgroundImage?.sourceUrl,
                  inlineStyles?.componentBackgroundRepeat
                )}')`
              : null,
            backgroundColor: inlineStyles?.componentBackgroundColor || null,
            backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
            backgroundRepeat: inlineStyles?.componentBackgroundRepeat || 'no-repeat',
            backgroundPosition: inlineStyles?.componentBackgroundPosition || 'left center',
          }}
        >
          {filteredRooms
            ?.sort(promoteAvailable)
            .sort(handlePriceSort)
            .map((r, index) => {
              const rate =
                location?.continent === 'EU'
                  ? r?.moreRatesFromRate?.amountAfterTaxFmt
                  : r?.moreRatesFromRate?.rateAmountFmt;

              const ctaData: RoomsCTAProps = {
                room: r,
                arrivalDate: dates?.arrival,
                departureDate: dates?.departure,
                rate,
                ctyhocn,
                currency: potentiallyPreviousData?.hotel?.shopAvail?.currency,
                hasPrices: !isLoading && !isError && !hideLeadRates && hasPrices,
                guests,
              };
              return (
                <RoomCard
                  key={r.roomTypeCode}
                  ind={index}
                  room={r}
                  rate={rate}
                  ctyhocn={ctyhocn}
                  ctaData={ctaData}
                  loading={isLoading}
                  status={status}
                  queryData={data}
                  isError={!!isError}
                  hideLeadRate={hideLeadRates}
                  pricingEnabled={pricingEnabled}
                  icons={iconData({
                    room: r,
                    squareFootageText: t('squareFootage', {
                      sqFt: r?.squareFootage,
                    }),
                  }).filter(Boolean)}
                  inlineStyles={inlineStyles}
                  textAlignment={textAlignment}
                  currency={potentiallyPreviousData?.hotel?.shopAvail?.currency}
                  resEnabled={resEnabled}
                  associatedHotels={associatedHotels}
                  guests={guests}
                  hasPrices={!isLoading && !isError && !hideLeadRates && hasPrices}
                  dates={dates}
                  enableHHR={sharedContext?.enableHHR}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
}

function remove<T>(arr: T[], item: T) {
  const index = arr.findIndex((i) => i === item);
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

export interface State {
  view: string[];
  balconyDetail: string[];
  recommendedFor: string[];
  numBeds: string[];
  guests: string[];
  adaAccessibleRoom: string[];
  premiumOptions: string[];
  outdoorFeatures: string[];
  roomFeatures: string[];
  roomType: string[];
  bathroomAmenities: string[];
  locations: string[];
  customFilter1: string[];
  customFilter2: string[];
  customFilter3: string[];
  bedType: string[];
}

export interface Action {
  key: keyof Filters;
  checked: boolean;
  item: string | number | boolean;
}
interface ClearAll {
  clearAll: boolean;
}

function isClearAll(a: Action | ClearAll): a is ClearAll {
  return (a as ClearAll).clearAll !== undefined;
}

function reducer(state: State, action: Action | { clearAll: boolean }) {
  if (isClearAll(action)) {
    window.history.replaceState(null, null, '?');
    return {
      view: [],
      balconyDetail: [],
      guests: [],
      numBeds: [],
      brandName: [],
      adaAccessibleRoom: [],
      recommendedFor: [],
      premiumOptions: [],
      outdoorFeatures: [],
      roomFeatures: [],
      roomType: [],
      bathroomAmenities: [],
      showerTubAmenities: [],
      locations: [],
      customFilter1: [],
      customFilter2: [],
      customFilter3: [],
      bedType: [],
    };
  }

  if (action.key === 'showerTubAmenities') {
    switch (action.item) {
      case '':
        return {
          ...state,
          showerTubAmenities: [],
        };
    }
  }

  const params = new URLSearchParams(window.location.search);
  let paramVals = params.get(action.key)?.split(',');
  if (!paramVals) {
    paramVals = [];
  }
  if (action.checked) {
    if (action.key === 'guests' && paramVals.length) {
      paramVals = [String(action.item)];
    } else if (!paramVals.includes(String(action.item))) {
      paramVals.push(String(action.item));
    }
    params.set(action.key, paramVals.join(','));
    window.history.replaceState(null, null, '?' + params.toString());
    return {
      ...state,
      [action.key]: action.key === 'guests' ? [action.item] : [...state[action.key], action.item],
    };
  }
  // uncheck
  if (paramVals.includes(String(action.item))) {
    paramVals = paramVals.filter((item) => item !== String(action.item));
  }
  paramVals = paramVals.filter((item) => item !== '' || item === undefined);
  if (paramVals?.length === 0) {
    params.delete(action.key);
  } else {
    params.set(action.key, paramVals.join(','));
  }
  window.history.replaceState(null, null, '?' + params.toString());
  return {
    ...state,
    [action.key]: action.key === 'guests' ? [] : remove(state[action.key], action.item),
  };
}
