import { useContext } from 'react';
import { HeadingStyle } from './includes/heading-style';
import type { ACFAnchorProps } from './global/anchor-link';
import { AnchorLink } from './global/anchor-link';
import type { StyleObject } from './functions/global-instance-styles';
import { GIS_merge, GIS_TextAlignment, GIS_Padder } from './functions/global-instance-styles';
import { CroppedImage, customLoader } from './cropped-image/cropped-image';
import {
  WrappedSubtitle,
  appliedCloudinaryParams,
  ParallaxLayer,
  SharedContext,
} from '@curated-property/utils';
import cx from 'classnames';
import { HandleWYSIWYGContent, useMediaQuery, HandleAnimations } from './functions/helper';
import contentStyles from './css/contentStyle.module.css';
import type { WordpressPageInfoQuery } from '@dx-ui/queries-dx-curated-ui/generated/wp';

/**
 * Maps the content from the CMS to the components within our project.
 *
 * @param data - the data we want to map from our services.
 * @param global - the global styles from the CMS.
 * @returns - the data mapped to the interface of our component.
 */

export function twoOneMediaCopyPropMapper(
  data: TwoOneMediaCopyProps & { twoOneMediaCopySettings?: StyleObject },
  global: NonNullable<
    NonNullable<WordpressPageInfoQuery['componentStyles']>['globalComponentSettings']
  >['globalComponentSettings']
) {
  return {
    title: data.title,
    title_noTx: data.title_noTx,
    subtitle: data.subtitle,
    subtitle_noTx: data.subtitle_noTx,
    copy: data.copy,
    buttons: data.buttons,
    largeImage: data.largeImage,
    smallImage: data.smallImage,
    additionalSmallImage: data?.additionalSmallImage,
    imageLargeCropHeight: data.imageLargeCropHeight,
    imageLargeCropWidth: data.imageLargeCropWidth,
    imageLargeEnableCropping: data.imageLargeEnableCropping,
    imageLargeCropType: data.imageLargeCropType,
    imageLargeXPosition: data.imageLargeXPosition,
    imageLargeYPosition: data.imageLargeYPosition,
    imageLargeXPositionAdvanced: data.imageLargeXPositionAdvanced,
    imageLargeYPositionAdvanced: data.imageLargeYPositionAdvanced,
    imageSmallCropHeight: data.imageSmallCropHeight,
    imageSmallCropWidth: data.imageSmallCropWidth,
    imageSmallEnableCropping: data.imageSmallEnableCropping,
    imageSmallCropType: data.imageSmallCropType,
    imageSmallXPosition: data.imageSmallXPosition,
    imageSmallYPosition: data.imageSmallYPosition,
    imageSmallXPositionAdvanced: data.imageSmallXPositionAdvanced,
    imageSmallYPositionAdvanced: data.imageSmallYPositionAdvanced,
    imageInsetBorderColour: data?.twoOneMediaCopySettings?.imageInsetBorderColour,
    instanceStyles: data?.twoOneMediaCopySettings,
    globalStyles: global?.twoOneMediaCopySettings,
    flipLargeImageHorizontally: data.flipLargeImageHorizontally,
  };
}

interface ImgProps {
  sourceUrl?: string;
  altText?: string;
}

export interface TwoOneMediaCopyProps {
  title?: string;
  title_noTx?: string;
  subtitle?: string;
  subtitle_noTx?: string;
  copy?: string;
  buttons?: Array<ACFAnchorProps>;
  largeImage?: ImgProps;
  smallImage?: ImgProps;
  additionalSmallImage?: ImgProps;
  imageLargeCropHeight?: string;
  imageLargeCropWidth?: string;
  imageLargeEnableCropping?: boolean;
  imageLargeCropType?: string;
  imageLargeXPosition?: string;
  imageLargeYPosition?: string;
  imageLargeXPositionAdvanced?: string;
  imageLargeYPositionAdvanced?: string;
  imageSmallCropHeight?: string;
  imageSmallCropWidth?: string;
  imageSmallEnableCropping?: boolean;
  imageSmallCropType?: string;
  imageSmallXPosition?: string;
  imageSmallYPosition?: string;
  imageSmallXPositionAdvanced?: string;
  imageSmallYPositionAdvanced?: string;
  imageInsetBorderColour?: string;
  instanceStyles?: StyleObject;
  globalStyles?: StyleObject;
  flipLargeImageHorizontally?: boolean;
}

export const TwoOneSmallImage: React.FC<{
  alt?: string;
  animationString?: string;
  aspectRatio?: string;
  className?: string;
  cropType?: string;
  cropHeight?: string;
  cropWidth?: string;
  position: {
    x: string;
    xAdvanced: string;
    y: string;
    yAdvanced: string;
  };
  enableCropping?: boolean;
  src?: string;
}> = ({
  alt = '',
  animationString = '',
  className,
  cropHeight = '',
  cropType = '',
  cropWidth = '',
  enableCropping = false,
  position: { x, xAdvanced, y, yAdvanced },
  src = '',
}) => {
  if (!alt && !src) return null;

  return (
    <CroppedImage
      className={cx('w-full', className, animationString)}
      loader={() => {
        return customLoader({
          src: src || '',
          width: '647',
          height: '455',
          crop: enableCropping,
          cropType: cropType || '',
          cropHeight: cropHeight || '',
          cropWidth: cropWidth || '',
          xPosition: x || '',
          xPositionAdvanced: xAdvanced || '',
          yPosition: y || '',
          yPositionAdvanced: yAdvanced || '',
        });
      }}
      src={src}
      width={647}
      height={455}
      alt={alt}
    />
  );
};

const hasImg = (img: ImgProps): boolean => {
  return img?.altText !== undefined && img?.sourceUrl !== undefined;
};

export function TwoOneMediaCopy({
  title,
  title_noTx,
  subtitle,
  subtitle_noTx,
  copy,
  buttons,
  largeImage,
  smallImage,
  additionalSmallImage,
  imageLargeCropHeight,
  imageLargeCropWidth,
  imageLargeEnableCropping,
  imageLargeCropType,
  imageLargeXPosition,
  imageLargeYPosition,
  imageLargeXPositionAdvanced,
  imageLargeYPositionAdvanced,
  imageSmallCropHeight,
  imageSmallCropWidth,
  imageSmallEnableCropping,
  imageSmallCropType,
  imageSmallXPosition,
  imageSmallYPosition,
  imageSmallXPositionAdvanced,
  imageSmallYPositionAdvanced,
  imageInsetBorderColour,
  instanceStyles,
  globalStyles,
  flipLargeImageHorizontally,
}: TwoOneMediaCopyProps) {
  const isMobile = useMediaQuery(768);
  const inlineStyles = GIS_merge(globalStyles, instanceStyles);
  const textAlignment = GIS_TextAlignment(inlineStyles?.textAlignment);
  const paddingStyles = GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom);

  const hasTwoSmallImages = hasImg(smallImage) && hasImg(additionalSmallImage);
  const hasInsetBorder = imageInsetBorderColour !== null;

  const sharedContext = useContext(SharedContext);
  const enableHHR = sharedContext?.enableHHR;

  const borderDefaultColor = enableHHR ? '' : 'white';

  const borderColor = inlineStyles?.smallImageBorderColour || borderDefaultColor;

  const smallImgBorderCondSetting =
    enableHHR && !inlineStyles?.smallImageBorderWidth ? 3 : inlineStyles?.smallImageBorderWidth;

  const padding = `${smallImgBorderCondSetting * 4}px`;
  const innerBoxShadow = (inlineStyles?.smallImageBorderWidth * 4 - 1) / 2;
  const insetBoxShadow = innerBoxShadow + 1;
  const outerBoxShadow = insetBoxShadow + innerBoxShadow;

  const insetBorder = hasInsetBorder
    ? `inset 0 0 0 ${innerBoxShadow}px ${borderColor}, inset 0 0 0 ${insetBoxShadow}px ${imageInsetBorderColour}, inset 0 0 0 ${outerBoxShadow}px ${borderColor}`
    : undefined;

  const twoImageHeight = isMobile ? 840 : 1300;

  // horizontal layout direction
  const horzLayout = inlineStyles?.horizontalDirection === 'reverse' ? 'lg:flex-row-reverse' : '';
  const horzTranslate =
    inlineStyles?.horizontalDirection === 'reverse' ? 'lg:-translate-x-12' : 'lg:translate-x-12';
  const horzItemsAlignment =
    inlineStyles?.horizontalDirection === 'reverse' ? 'items-start' : 'items-end';
  // vertical layout direction
  const vertLayout = inlineStyles?.verticalDirection === 'reverse' ? 'lg:flex-col-reverse' : '';
  const vertTranslate =
    inlineStyles?.verticalDirection === 'reverse' ? 'lg:translate-y-8' : 'lg:-translate-y-8';
  const vertItemsAlignment =
    inlineStyles?.verticalDirection === 'reverse' ? 'items-start' : 'items-end';

  const animations = HandleAnimations({
    hideAnimation: inlineStyles?.hideAnimations !== 'show',
    start: inlineStyles?.animationDirection
      ? `lg:${inlineStyles?.animationDirection}-8`
      : inlineStyles?.horizontalDirection === 'reverse'
      ? 'lg:translate-x-8'
      : 'lg:-translate-x-8',
    delayOne: 'delay-200',
    delayTwo: 'delay-300',
    delayThree: 'delay-500',
    delayFour: 'delay-700',
  });

  let parallaxSpeed = useMediaQuery(1024) ? 25 : 85;
  if (inlineStyles?.verticalDirection === 'reverse') {
    parallaxSpeed *= -1;
  }

  return (
    <section
      ref={animations?.ref}
      className={inlineStyles?.showHide ? 'hidden' : ''}
      style={{
        backgroundImage: inlineStyles?.componentBackgroundImage?.sourceUrl
          ? `url('${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage?.sourceUrl,
              inlineStyles?.componentBackgroundRepeat
            )}')`
          : undefined,
        backgroundColor: inlineStyles?.componentBackgroundColor || undefined,
        backgroundSize: inlineStyles?.componentBackgroundSize || undefined,
        backgroundRepeat: inlineStyles?.componentBackgroundRepeat || 'no-repeat',
        backgroundPosition: inlineStyles?.componentBackgroundPosition || 'top left',
      }}
      data-element-id="two-one-media-and-copy-wrapper"
    >
      <div className={cx('container flex flex-col lg:flex-row', horzLayout, paddingStyles)}>
        <div
          className={cx(
            'order-2 flex w-full flex-col lg:order-1 lg:w-5/12',
            horzItemsAlignment,
            vertLayout
          )}
        >
          {/* copy block */}
          <div
            className={cx('bg-bg-alt w-full p-6 lg:py-20 xl:px-24 xl:py-28', horzTranslate, {
              'lg:px-16': !hasTwoSmallImages,
              'border-box lg:ps-28 lg:pe-16': hasTwoSmallImages,
            })}
            style={{
              backgroundImage: inlineStyles?.contentBackgroundImage?.sourceUrl
                ? `url('${appliedCloudinaryParams(
                    inlineStyles?.contentBackgroundImage?.sourceUrl,
                    inlineStyles?.contentBackgroundRepeat
                  )}')`
                : undefined,
              backgroundColor: inlineStyles?.contentBackgroundColor || undefined,
              textAlign: textAlignment,
              alignItems: inlineStyles?.textAlignment,
              backgroundSize: inlineStyles?.contentBackgroundSize || undefined,
              backgroundRepeat: inlineStyles?.contentBackgroundRepeat || 'no-repeat',
              backgroundPosition: inlineStyles?.contentBackgroundPosition || 'left center',
            }}
          >
            <HeadingStyle
              text={inlineStyles?.headingsNoTranslate ? title_noTx : title}
              type="h2"
              className={cx('mb-4', animations?.one, {
                OneLinkNoTx: inlineStyles?.headingsNoTranslate,
              })}
              textColorInline={inlineStyles?.titleColor}
            />
            {subtitle ? (
              <WrappedSubtitle>
                <HeadingStyle
                  text={inlineStyles?.headingsNoTranslate ? subtitle_noTx : subtitle}
                  type="p"
                  styledAs="h2"
                  className={cx('-mt-4 mb-4', animations?.two, {
                    OneLinkNoTx: inlineStyles?.headingsNoTranslate,
                  })}
                  textColorInline={inlineStyles?.subtitleColor}
                />
              </WrappedSubtitle>
            ) : (
              false
            )}
            <div
              className={cx(
                'twoOneMediaCopy--copy text-lg',
                contentStyles.paragraphStyle,
                contentStyles.listStyle,
                animations?.two
              )}
              style={{ color: inlineStyles?.textColor }}
              dangerouslySetInnerHTML={{
                __html: HandleWYSIWYGContent(copy, inlineStyles?.textColor),
              }}
            />
            {buttons ? (
              <div className={`mt-6 ${animations?.three}`}>
                {buttons?.map((link, key) => {
                  if (!link) {
                    return null;
                  }
                  return (
                    <AnchorLink
                      key={key}
                      url={link.link?.url || ''}
                      title={link.link?.title || ''}
                      target={link.link?.target || ''}
                      buttonStyle={link?.buttonStyle ?? 'primary-outline'}
                    />
                  );
                })}
              </div>
            ) : null}
          </div>
          {/* small image */}
          <div
            style={{
              backgroundColor: inlineStyles?.smallImageBorderColour || null,
              boxShadow: insetBorder,
              padding,
            }}
            className={cx(
              `bg-bg z-2 relative box-border hidden w-[calc(100%+3rem)] text-[0px] lg:block`,
              horzTranslate,
              vertTranslate
            )}
            data-element-id="small-image-wrapper"
          >
            <TwoOneSmallImage
              alt={smallImage?.altText}
              cropHeight={imageSmallCropHeight}
              cropType={imageSmallCropType}
              cropWidth={imageSmallCropWidth}
              enableCropping={imageSmallEnableCropping}
              position={{
                x: imageSmallXPosition,
                xAdvanced: imageSmallXPositionAdvanced,
                y: imageSmallYPosition,
                yAdvanced: imageSmallYPositionAdvanced,
              }}
              src={smallImage?.sourceUrl}
            />
            {hasImg(additionalSmallImage) ? (
              <>
                {/* divider between images */}
                <div
                  className="p-2"
                  style={{
                    padding: inlineStyles?.smallImageBorderWidth
                      ? `${outerBoxShadow / 2}px`
                      : undefined,
                  }}
                />
                <TwoOneSmallImage
                  alt={additionalSmallImage?.altText}
                  cropHeight={imageSmallCropHeight}
                  cropType={imageSmallCropType}
                  cropWidth={imageSmallCropWidth}
                  position={{
                    x: imageSmallXPosition,
                    xAdvanced: imageSmallXPositionAdvanced,
                    y: imageSmallYPosition,
                    yAdvanced: imageSmallYPositionAdvanced,
                  }}
                  src={additionalSmallImage?.sourceUrl}
                />
              </>
            ) : null}
          </div>
        </div>
        {/* large image */}

        <div
          className={cx(
            'relative order-1 w-full lg:order-2 lg:flex lg:w-7/12 lg:justify-start',
            vertItemsAlignment,
            {
              'isolate scale-x-[-1]': flipLargeImageHorizontally,
            }
          )}
        >
          <ParallaxLayer
            hideAnimations={animations?.hideAnimations || inlineStyles?.hideParallax === 'hide'}
            inView={animations?.inView}
            parallaxSpeed={parallaxSpeed}
          >
            <CroppedImage
              className={cx('z-1 relative aspect-square w-full object-cover', {
                'lg:aspect-[4/3]': !hasTwoSmallImages,
                'lg:aspect-[9/16]': hasTwoSmallImages,
              })}
              loader={() => {
                return customLoader({
                  src: largeImage?.sourceUrl || '',
                  width: '840',
                  height: hasTwoSmallImages ? twoImageHeight : '760',
                  crop: imageLargeEnableCropping || false,
                  cropType: imageLargeCropType || '',
                  cropHeight: imageLargeCropHeight || '',
                  cropWidth: imageLargeCropWidth || '',
                  xPosition: imageLargeXPosition || '',
                  xPositionAdvanced: imageLargeXPositionAdvanced || '',
                  yPosition: imageLargeYPosition || '',
                  yPositionAdvanced: imageLargeYPositionAdvanced || '',
                });
              }}
              src={largeImage?.sourceUrl || ''}
              width={840}
              height={hasTwoSmallImages ? twoImageHeight : 760}
              alt={largeImage?.altText}
              layout={!useMediaQuery(1023) ? 'intrinsic' : 'responsive'}
            />
          </ParallaxLayer>
        </div>
      </div>
    </section>
  );
}
