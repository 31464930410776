import gql from 'graphql-tag-transform.macro';
import {
  twoColumnIconListSettings,
  tabbedTableDataSettings,
  galleryWallSettings,
  imageGallerySettings,
  googleMapSettings,
  htmlMenuSettings,
  keyInfoGridSettings,
  hotelPoliciesSettings,
  mediaAndCopySettings,
  mediaAndCopyCarouselSettings,
  twoOneMediaCopySettings,
  offersCuratedComponentSettings,
  iconGridCorePlusSettings,
  iconGridSettings,
  multiColumnTilesWithHoverSettings,
  copyBlockSettings,
  hotelTitleSettings,
  fullWidthMediaComponentSettings,
  roomsComponentSettings,
  meetingGroupSettings,
  meetingCapacityCharts,
  meetingCapacitySettings,
  accordionSettings,
  eventsCalendarSettings,
  gallerySliderComponentSettings,
  informationBarComponentSettings,
  videoMediaCopyComponentSettings,
  multiColumnListComponentSettings,
  mediaAndCopyOverlaySettings,
} from './wp/common-vars';
import {
  Accordion,
  ComparisonTable,
  ContactBar,
  CopyBlock,
  Cvent,
  EditorialSnippet,
  EventsCalendarRegularAndSpecialEventsGrid,
  FullWidthImage,
  GallerySlider,
  GoogleMap,
  HeroImage,
  HotelPolicies,
  HotelTitle,
  HtmlMenu,
  IconGridCorePlus,
  IconGrid,
  ImageGalleryWall,
  ImageGallery,
  ImageReveal,
  InformationBar,
  KeyInfoGrid,
  LogoGrid,
  MediaAndCopyCarousel,
  MediaAndCopyOverlay,
  MediaAndCopy,
  MeetingCapacityCharts,
  MeetingCapacity,
  MeetingsGroup,
  MultiColumnList,
  MultiColumnTilesCarousel,
  MultiColumnTiles,
  OffersCurated,
  Offset,
  PatchworkGrid,
  ResortMap,
  Restaurants,
  RetailFlyout,
  RetailRail,
  RoomTilesLite,
  RoomsTiles,
  SocialMediaFeed,
  Spacer,
  TabbedTableData,
  TwoColumnCompare,
  TwoColumnHoverTiles,
  TwoColumnIconList,
  TwoOneMediaCopy,
  TwoTwoMediaCopy,
  VideoMediaCopy,
  WeddingsAvailabilityCalendar,
} from './wp/index';

const NavFragment = gql`
  fragment NavFragment on RootQuery {
    mainNav: menuItems(
      first: 100
      where: { location: MAIN_NAVIGATION, language: $language, parentId: "0" }
    ) {
      edges {
        node {
          label
          label_noTx: label
          databaseId
          path
          url
          NoTranslate {
            noTx
          }
          connectedNode {
            node {
              databaseId
            }
          }
          childItems {
            edges {
              node {
                databaseId
                label
                label_noTx: label
                path
                url
                NoTranslate {
                  noTx
                }
                connectedNode {
                  node {
                    databaseId
                  }
                }
                childItems {
                  edges {
                    node {
                      databaseId
                      label
                      label_noTx: label
                      path
                      url
                      NoTranslate {
                        noTx
                      }
                      connectedNode {
                        node {
                          databaseId
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    iconNav: menuItems(
      first: 100
      where: { location: MINI_NAVIGATION, language: $language, parentId: "0" }
    ) {
      edges {
        node {
          label
          databaseId
          path
          iconSelect {
            iconSelect
          }
          menu {
            node {
              locations
            }
          }
        }
      }
    }
    languageNavStyles: menu(id: "Language Menu", idType: NAME) {
      name
      languageSelector {
        languageNavAccentColour
        languageNavTextColour
      }
    }
    footerNav: menuItems(
      first: 100
      where: { location: FOOTER_NAVIGATION, language: $language, parentId: "0" }
    ) {
      edges {
        node {
          label
          databaseId
          path
          menu {
            node {
              locations
              count
            }
          }
        }
      }
    }
  }
`;

export const getAllMenusQuery = gql`
  query allWPMenus($language: String) {
    ...NavFragment
  }
  ${NavFragment}
`;

export const getAllWPPagesQuery = gql`
  query wordpressPages {
    pages(last: 50) {
      nodes {
        uri
        title
      }
    }
  }
`;

const WPThemeSettingsFragment = gql`
  fragment WPSettings on RootQuery {
    allSettings {
      generalSettingsTitle
      generalSettingsDescription
    }

    themeSettings {
      SettingsThemeSettings {
        brandTheme
        useHhrBrandTheme
        customEventCategories {
          customCategoryNames
          customCategoryNames_noTx: customCategoryNames
          fieldGroupName
        }
      }
    }

    propertyInfo {
      propertyInfo {
        liveUrl
        brand
        ctyhocn
        faxNumber
        openingHours
        phoneNumber
        address {
          addressCountry
          addressLocality
          addressRegion
          postalCode
          streetAddress
        }
        geo {
          latitude
          longitude
        }
      }
    }

    socialLink {
      pageSlug
      pageTitle
      socialLinks {
        fieldGroupName
        facebookLink
        instagramLink
        twitterLink
        linkedinLink
      }
    }

    headerSettings {
      pageTitle
      SettingsHeaderSettings {
        fieldGroupName
        backgroundColour
        backgroundColourActive
        backgroundColourGradient
        backgroundBlur
        backgroundBlurActive
        textColour
        textColourActive
        dividerColour
        dividerColourActive
        bookingWidgetButtonStyles {
          fieldGroupName
          buttonStyle
          backgroundColour
          backgroundColourHover
          textCase
          textColour
          textColourHover
          borderColour
          borderColourHover
          hideBookingWidget
        }
        iconNavStyles {
          fieldGroupName
          iconColour
          iconColourActive
          separatorColour
          textColour
          textColourActive
          underline
        }
        mainNavStyles {
          fieldGroupName
          fontCase
          fontSize
          fontWeight
          menuItemActiveBackgroundColour
          menuItemActiveState
          menuItemActiveTextColour
          menuItemHoverColour
          menuItemPadding
          menuItemUnderlineColour
          menuItemUnderlineToggle
          submenuBackgroundColour
        }
        honorsSignInStyles {
          backgroundColour
          menuLinkBackgroundHighlightColour
          menuLinkTextHighlightColour
          textColour
        }
        languageSwitchButtonStyles {
          backgroundColour
          backgroundColourHover
          textCase
          textColour
          textColourHover
        }
        languageSwitchDropdownStyles {
          backgroundColour
          backgroundColourHover
          dividerColour
          textColour
          textColourHover
        }          
        languageSwitchTitleStyles {
          textCase
          textColour
          textColourActive
          textColourActiveHover
          textColourHover
        }
        languageSwitchButtonStylesActive {
          backgroundColour
          backgroundColourHover
          textCase
          textColour
          textColourHover
        }
      }
    }
    logoSettings {
      SettingsLogoSettings {
        brandLogoClasses
        brandLogoClassesFooter
        brandLogoColourActive
        brandLogoColourFooter
        brandLogoColourInactive
        hotelLogo {
          altText
          sourceUrl
        }
        brandLogo {
          altText
          sourceUrl
        }
        hotelLogoClasses
        hotelLogoClassesFooter
        hotelLogoColourActive
        hotelLogoColourFooter
        hotelLogoColourInactive
        additionalLogos {
          margin
          maxWidth
          tailwindClasses
          image {
            altText
            sourceUrl
          }
        }
      }
    }
    translationSettings {
      SettingsTranslations {
        languageSelectorOverrides {
          languageCode
          reservationsOnly
          urlOverride
        }
      }
    }
    buttonSettings {
      buttonsSettings {
        buttonStyles {
          backgroundColour
          backgroundColourHover
          borderColour
          borderColourHover
          borderRadius
          buttonType
          fieldGroupName
          fontFamily
          hoverStyle
          textColour
          textColourHover
          textTransform
          buttonHeight
          buttonPadding
          fontSize
          fontWeight
          pageRestrictions
          lineHeight
        }
        waldorfButtonStyles {
          buttonType
          textColour
          textColourHover
          underlineColour
          underlineColourHover
          pageRestrictions
        }
      }
    }
    footerSettings {
      SettingsFooterSettings {
        footer {
          backgroundImage {
            sourceUrl
          }
          backgroundColour
          disclaimerText
          textColour
          dividerColour
        }
        breadcrumbs {
          activeTextColour
          backgroundColour
          textColour
          delimiter
          delimiterColour
          delimiterPadding
        }
        copyright {
          backgroundColour
          enable
          textColour
        }
      }
    }
    roomSettings {
      pageTitle
      SettingsRoomSettings {
        roomFilters1Title
        roomFilters2Title
        roomFilters3Title
      }
    }
    alertBarSettings {
      alertBarSettings {
        backgroundColor
        linkColor
        textColor
      }
    }
    translationSettings {
      SettingsTranslations {
        translations {
          translationString
        }
      }
    }
    themeSettings {
      acfVersion
    }
    interactiveMapSettings {
      interactiveMapSettings {
        mapCategories {
          categoryName
          iconList
        }
      }
    }

    shopFormSettings {
      pageSlug
      pageTitle
      shopFormSettings {
        fieldGroupName
        attachToHeader
        checkboxBackgroundColor
        checkboxInactiveBackgroundColor
        checkboxOutlineCheckmarkColor
        dateNumberAndRoomIconColor
        dateTextColor
        largeDateNumbersLineHeight
        dayOfTheWeekColor
        dividingLineColor
        fieldGroupName
        horizontalDividingLineColor
        inputBorderColor
        mainTextColor
        modalBackgroundColor
        modalDividingLineColor
        modalHyperlinkColor
        modalHyperlinkColorCopy
        modalTextColor
        monthColor
        oscBookButtonBackgroundColor
        oscBookButtonBackgroundColorOnHover
        oscBookButtonBorderColor
        oscBookButtonBorderColorOnHover
        oscBookButtonTextColor
        oscBookButtonTextColorOnHover
        searchAndFilterIcons
        searchButtonBackgroundColor
        searchButtonBackgroundColorOnHover
        searchButtonBorderColor
        searchButtonBorderColorOnHover
        searchButtonTextColor
        searchButtonTextColorOnHover
        selectedBookDatePickerDateBackground
        selectedBookDatePickerDateText
        selectedBookPickerDateRangeBackground
        selectedBookPickerDateRangeText
        shopFormBgColor
        shopFormBackgroundBlur
        specialRatesNumberBackground
        specialRatesTextBackground
        bookButtonStyleFromGlobal {
          fieldGroupName
          shopFormBookGlobalButtonStyleButtonStyle
        }
        searchButtonStyleFromGlobal {
          fieldGroupName
          globalButtonStyleSettingButtonStyle
        }
        activeHorizontalDividingLineColor
        pastDatesText
        todaysDateText
        todaysDateBackground
        activeShopFormStyles {
          dateTextColor
          dayOfTheWeekColor
          dividingLineColor
          monthColor
        }
        activeBookButtons {
          backgroundColor
          backgroundColorOnHover
          borderColor
          borderColorOnHover
          textColor
          textColorOnHover
        }
        activeSearchButtons {
          backgroundColor
          backgroundColorOnHover
          borderColor
          borderColorOnHover
          textColor
          textColorOnHover
        }
        modalCancel {
          backgroundColor
          backgroundColorOnHover
          borderColor
          borderColorOnHover
          textColor
          textColorOnHover
        }
        modalDone {
          backgroundColor
          backgroundColorOnHover
          borderColor
          borderColorOnHover
          textColor
          textColorOnHover
        }
      }
    }

    componentStyles {
      globalComponentSettings {
        globalComponentSettings {
          addressBarSettings {
            fieldGroupName
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            subtitleColor
            textAlignment
            textColor
            titleColor
            borderTopColour
            borderBottomColour
            seperatorColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
          }
          mastheadComponentSettings {
            titleColor
            textColor
            textAlignment
            subtitleColor
            overlayYPosition
            overlayXPosition
            overlayMobilePosition
            overlayBackgroundOpacity
            overlayBackgroundColour
            overlayMobileBackgroundColour
            mobileTitleColour
            mobileSubtitleColour
            overlayBlur
            overlayFullWidth
            mastheadHeight
            mastheadGradient
            videoCtaBackgroundColor
            videoCtaBorderColor
            videoCtaMuteColor
            videoCtaPlaypauseColor
            videoCtaUnmuteColor
            videoMastheadCtaPosition
            videoCtaShadow
            contentBackgroundPosition
            logoXPosition
            logoYPosition
            heroCarouselArrowColour
            heroCarouselBackgroundColour
            heroCarouselTextColour
            heroCarouselCounterColour
            heroCarouselDividerColour
            heroCarouselRoundedCorners
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            mastheadSpacer
            mastheadSpacerColour
          }
          halfAndHalfComponentSettings {
            fieldGroupName
            accordionBorderColor
            accordionIconColor
            accordionIconColorActive
            accordionPanelBackgroundColor
            accordionPanelLabelColor
            accordionRowLabelColor
            accordionRowTextColor
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${mediaAndCopySettings}
          }
          singleItemSliderComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${mediaAndCopyCarouselSettings}
          }
          fullWidthImageComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${fullWidthMediaComponentSettings}
          }
          introductionComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${copyBlockSettings}
          }
          hotelTitleComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${hotelTitleSettings}
          }
          locationGoogleMapComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${googleMapSettings}
          }

          imageRevealComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd

            ${multiColumnTilesWithHoverSettings}
          }
          twoColumnIconListSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${twoColumnIconListSettings}
          }
          twoOneMediaCopySettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${twoOneMediaCopySettings}
          }
          twoTwoMediaCopySettings {
            fieldGroupName
            horizontalDirection
            #ClonedComponentSettingsStart
              componentBackgroundColor
              componentBackgroundSize
              componentBackgroundRepeat
              componentBackgroundPosition
              contentBackgroundColor
              contentBackgroundSize
              contentBackgroundRepeat
              contentBackgroundPosition
              contentBackgroundColorLayerTwo
              subtitleColor
              textAlignment
              textColor
              titleColor
              componentBackgroundImage {
                sourceUrl
              }
              contentBackgroundImage {
                sourceUrl
              }
              paddingTop {
                paddingTopDesktop
                paddingTopTablet
                paddingTopMobile
              }
              paddingBottom {
                paddingBottomDesktop
                paddingBottomTablet
                paddingBottomMobile
              }
              showHide
              hideAnimations
              animationDirection
              hideParallax
              sliderAnimations
              zoomOnHover
              containerMaxWidth
              headingsNoTranslate
            #ClonedComponentSettingsEnd
          }
          offersCuratedComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${offersCuratedComponentSettings}
          }
          multiItemCarouselComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            headerTitleColour
            headerCopyColour
            uiPrimaryColour
            uiSecondaryColour
            titleSize
            tileTitleBackgroundColour
            headingStyle
            tileTitleAlignment
            tileTitleLayout
          }
          socialMediaFeedSettings {
            fieldGroupName
            socialMediaIconColor
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
          }
          twoColumnCompareComponentSettings {
            fieldGroupName
            showHide
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
          }
          multiColumnComponentSettings {
            fieldGroupName
            showHide
            accordionBorderColor
            accordionIconColor
            accordionIconColorActive
            accordionPanelBackgroundColor
            accordionPanelLabelColor
            accordionRowLabelColor
            accordionRowTextColor
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            filterActiveTextColour
            filterBackgroundColour
            filterTextColour
            uiBackgroundColour
            uiControlsColour
            headerTitleColour
            headerCopyColour
            tileImageLabelBackgroundColor
            tileImageLabelIconColor
            tileImageLabelTextColor
            tileHorizontalDividerLineColor
          }
          spacerComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
          }
          retailFlyoutComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd


          }
          retailRailComponentSettings {
            componentBackgroundColor
            componentBackgroundPosition
            componentBackgroundRepeat
            componentBackgroundSize
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundColor
            contentBackgroundPosition
            contentBackgroundRepeat
            contentBackgroundSize
            contentBackgroundImage {
              sourceUrl
            }
            textAlignment
            textColor
            titleColor
            closeIconColour
            closeTextColour
            showHide
          }

          galleryWallComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd


            ${galleryWallSettings}
          }

          imageGalleryComponentSettings {
            fieldGroupName
            ${imageGallerySettings}
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
          }
          mediaAndCopyOverlayComponentSettings {
            fieldGroupName
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            subtitleColor
            textAlignment
            textColor
            titleColor
            mediaAndCopyPadding
            horizontalDirection
            zoomOnHover
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            containerMaxWidth
            ${mediaAndCopyOverlaySettings}
          }

          iconBlockComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${iconGridSettings}
          }

          htmlMenuSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${htmlMenuSettings}
          }

          iconBlockCorePlusComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${iconGridCorePlusSettings}
          }

          keyInfoGridSettings {
            fieldGroupName
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            ${keyInfoGridSettings}
          }

          roomsComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${roomsComponentSettings}
          }

          hotelPoliciesComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${hotelPoliciesSettings}
          }

          resortMapV2Settings {
            fieldGroupName
            showHide
            mapControlsColor
            mapControlsBackgroundColor
            tabBackgroundColor
            tabTextColor
            selectedTabBackgroundColor
            selectedTabTextColor
            mobileFilterDropdownBackgroundColor
            mapControlsPlacement
            mobileFilterDropdownTextColor
            pinAccentColor
            pinColor
            subtitleColor
            textAlignment
            titleColor
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
          }

          comparisonTableSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
          }

          onPageMenuSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd


            ${tabbedTableDataSettings}
          }

          fourStaggeredTilesComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd


          }

          restaurantsComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd hoursSeperatorColour
            hideTileBorder
            cuisineTypeTextColour
            cuisineTypeIconColour
            tileBorderColour
            topSectionBackgroundColour
          }

          accordionComponentSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${accordionSettings}
          }

          meetingCapacitySummaryComponentSettings {
            fieldGroupName
            ${meetingCapacitySettings}
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd


          }

          meetingGroupComponentSettings {
            fieldGroupName
            ${meetingGroupSettings}
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd


          }

          meetingCapacityChartsSettings {
            fieldGroupName
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${meetingCapacityCharts}
          }

          eventsCalendarSettings {
            fieldGroupName
            showHide
            ${eventsCalendarSettings}
          }

          gallerySliderComponentSettings {
            fieldGroupName
            showHide
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
            ${gallerySliderComponentSettings}
          }

          informationBarComponentSettings {
            fieldGroupName
            showHide
            ${informationBarComponentSettings}
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
          }

          videoMediaCopySettings {
            fieldGroupName
            showHide
            ${videoMediaCopyComponentSettings}
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            animationDirection
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
          }
          
          multiColumnListComponentSettings {
            ${multiColumnListComponentSettings}
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            animationDirection
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
          }

          patchworkGridSettings {
            imageCaptionBackgroundColor
            imageCaptionTextColor
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            animationDirection
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
          }

          editorialSnippetComponentSettings {
            componentLabel
            fieldGroupName
            showHide
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            animationDirection
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
        }

         offsetComponentSettings {
            imageCaptionTextColor
            imageCaptionBackgroundColor
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            animationDirection
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
          }

          logoGridComponentSettings {
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            animationDirection
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
          }
        }
      }
    }
    modalSettings {
      modalSettings {
        modalBackgroundOpacity
        modalBackgroundColour
        modalCloseButtonBackgroundColour
        modalCloseButtonIconColour
        contentBackgroundColour
        contentTextColour
        hideBoxShadow
      }
    }
  }
`;

export const getRecurringEventsQuery = gql`
  query recurringEvents {
    recurringevents(first: 50) {
      edges {
        node {
          title
          id
          databaseId
          slug
          RegularlyScheduledEvents {
            attractionEventLocation
            category
            description
            distanceFromResort
            fieldGroupName
            locationAtResort
            onSiteOrOffSite
            eventDisplayEndDate
            eventDisplayStartDate

            allDays {
              allDay
              endTime
              startTime
              heldDaily
              fieldGroupName
            }

            fridaySchedule {
              allDay
              endTime
              fieldGroupName
              scheduled
              startTime
            }
            image {
              sourceUrl(size: LARGE)
              altText
              title
              databaseId
            }
            buttons {
              buttonStyle
              link {
                target
                title
                url
              }
            }
            mondaySchedule {
              allDay
              endTime
              fieldGroupName
              scheduled
              startTime
            }
            saturdaySchedule {
              allDay
              endTime
              fieldGroupName
              scheduled
              startTime
            }
            sundaySchedule {
              allDay
              endTime
              fieldGroupName
              scheduled
              startTime
            }
            thursdaySchedule {
              allDay
              endTime
              fieldGroupName
              scheduled
              startTime
            }
            tuesdaySchedule {
              allDay
              endTime
              fieldGroupName
              scheduled
              startTime
            }
            wednesdaySchedule {
              startTime
              scheduled
              fieldGroupName
              endTime
              allDay
            }
          }
        }
      }
    }
    calendars(first: 50) {
      edges {
        node {
          title
          calendarId
          calendarOfEvents {
            attractionEventLocation
            category
            description
            distanceFromResort
            endDate
            endTime
            fieldGroupName
            locationAtResort
            onSiteOrOffSite
            startDate
            startTime
            image {
              altText
              sourceUrl(size: LARGE)
              databaseId
            }
            buttons {
              link {
                target
                title
                url
              }
              buttonStyle
            }
          }
        }
      }
    }
  }
`;

export const getWPThemeSettingsQuery = gql`
  query wordpressThemeSettings {
    ...WPSettings
  }
  ${WPThemeSettingsFragment}
`;

export const getLanguagePageIDsQuery = gql`
  query wordpressPageTranslationInfo($id: ID!, $idType: PageIdType!, $asPreview: Boolean) {
    page(id: $id, idType: $idType, asPreview: $asPreview) {
      databaseId
      translations {
        locale
        id
      }
    }
  }
`;

export const PageFieldsFrag = gql`
  fragment PageFields on Page {
    slug
    title
    pageId
    isFrontPage
    parent {
      node {
        slug
        ... on Page {
          title
          parent {
            node {
              slug
              ... on Page {
                title
              }
            }
          }
        }
      }
    }
    metaDataFields {
      fieldGroupName
      metaTitle
      metaDescription
      adobeDigitaldataOverwriteList
      adobeDigitaldataOverwriteText
      adobeDigitaldataOverwriteBrand
      adobeDigitaldataOverwriteCtyhocn
      adobeDigitaldataPageDetail1
      adobeDigitaldataPageDetail2
      adobeDigitaldataPageDetail3
      openGraphDescription
      openGraphTitle
      openGraphImage {
        sourceUrl
      }
      ctyhocnOverride
    }
    acfFlexible {
      fieldGroupName
      flexibleComponents {
        __typename
        ...Accordion
        ...ComparisonTable
        ...ContactBar
        ...CopyBlock
        ...Cvent
        ...EditorialSnippet
        ...EventsCalendarRegularAndSpecialEventsGrid
        ...FullWidthImage
        ...GallerySlider
        ...GoogleMap
        ...HeroImage
        ...HotelPolicies
        ...HotelTitle
        ...HtmlMenu
        ...IconGridCorePlus
        ...IconGrid
        ...ImageGalleryWall
        ...ImageGallery
        ...ImageReveal
        ...InformationBar
        ...KeyInfoGrid
        ...LogoGrid
        ...MediaAndCopyCarousel
        ...MediaAndCopyOverlay
        ...MediaAndCopy
        ...MeetingCapacityCharts
        ...MeetingCapacity
        ...MeetingsGroup
        ...MultiColumnList
        ...MultiColumnTilesCarousel
        ...MultiColumnTiles
        ...OffersCurated
        ...Offset
        ...PatchworkGrid
        ...ResortMap
        ...Restaurants
        ...RetailFlyout
        ...RetailRail
        ...RoomTilesLite
        ...RoomsTiles
        ...SocialMediaFeed
        ...Spacer
        ...TabbedTableData
        ...TwoColumnCompare
        ...TwoColumnHoverTiles
        ...TwoColumnIconList
        ...TwoOneMediaCopy
        ...TwoTwoMediaCopy
        ...VideoMediaCopy
        ...WeddingsAvailabilityCalendar
      }
    }
  }
  ${Accordion}
  ${ComparisonTable}
  ${ContactBar}
  ${CopyBlock}
  ${Cvent}
  ${EditorialSnippet}
  ${EventsCalendarRegularAndSpecialEventsGrid}
  ${FullWidthImage}
  ${GallerySlider}
  ${GoogleMap}
  ${HeroImage}
  ${HotelPolicies}
  ${HotelTitle}
  ${HtmlMenu}
  ${IconGridCorePlus}
  ${IconGrid}
  ${ImageGalleryWall}
  ${ImageGallery}
  ${ImageReveal}
  ${InformationBar}
  ${KeyInfoGrid}
  ${LogoGrid}
  ${MediaAndCopyCarousel}
  ${MediaAndCopyOverlay}
  ${MediaAndCopy}
  ${MeetingCapacityCharts}
  ${MeetingCapacity}
  ${MeetingsGroup}
  ${MultiColumnList}
  ${MultiColumnTilesCarousel}
  ${MultiColumnTiles}
  ${OffersCurated}
  ${Offset}
  ${PatchworkGrid}
  ${ResortMap}
  ${Restaurants}
  ${RetailFlyout}
  ${RetailRail}
  ${RoomTilesLite}
  ${RoomsTiles}
  ${SocialMediaFeed}
  ${Spacer}
  ${TabbedTableData}
  ${TwoColumnCompare}
  ${TwoColumnHoverTiles}
  ${TwoColumnIconList}
  ${TwoOneMediaCopy}
  ${TwoTwoMediaCopy}
  ${VideoMediaCopy}
  ${WeddingsAvailabilityCalendar}
`;

const AllWPPages = gql`
  fragment AllWPPages on RootQuery {
    allWPPages: pages(last: 50, where: { orderby: { field: MENU_ORDER, order: DESC } }) {
      nodes {
        uri
        title
        id
        acfFlexible {
          flexibleComponents {
            ... on Page_Acfflexible_FlexibleComponents_Masthead {
              image {
                sourceUrl
              }
              imageCarousel {
                sourceUrl
              }
            }
          }
        }
        slug
        parent {
          node {
            uri
            slug
          }
        }
      }
    }
  }
`;

export const WACQuery = gql`
  query WeddingAvailabilityCalendar($minTimestamp: Float) {
    weddingbookings(where: { minTimestamp: $minTimestamp }, first: 512) {
      edges {
        node {
          id
          title
          weddingCalendar {
            ceremonyTime
            date
            fieldGroupName
            receptionVenue
            status
            timestamp
          }
        }
      }
    }
    themeSettings {
      SettingsThemeSettings {
        venuesTimeSlots {
          timeSlot
        }
        weddingVenues {
          venue
        }
      }
    }
  }
`;

export const RoomsPostQuery = gql`
  query RoomsPosts {
    rooms(last: 100) {
      edges {
        node {
          title
          roomsContent {
            roomTypeCode
            view
            view_noTx: view
            balconyDetail
            balconyDetail_noTx: balconyDetail
            customView
            customView_noTx: customView
            customBalcony
            customBalcony_noTx: customBalcony
            squareFootage
            outdoorFeatures
            roomFeatures
            roomType
            bathroomAmenities
            locations
            image {
              sourceUrl
              altText
            }
            icon {
              altText
              sourceUrl
            }
            iconWidth
            carouselImages {
              image {
                sourceUrl(size: _1920X1080)
                altText
                mediaDetails {
                  height
                  width
                }
              }
            }
            showCustomFilter1 {
              customFilter1Select
              showCustomFilter1
            }
            showCustomFilter2 {
              customFilter2Select
              showCustomFilter2
            }
            showCustomFilter3 {
              customFilter3Select
              showCustomFilter3
            }
            balconyIconGroup {
              iconList
              iconList_noTx: iconList
            }
            viewIconGroup {
              iconList
              iconList_noTx: iconList
            }
            squareFootageIconGroup {
              iconList
              iconList_noTx: iconList
            }
          }
        }
      }
    }
  }
`;

export const HTMLMenuQuery = gql`
  query HTMLMenus {
    htmlMenus(last: 100) {
      edges {
        node {
          id
          title
          htmlMenuContent {
            menuRepeater {
              menuSections {
                fieldGroupName
                itemDescriptionColour
                itemDisclaimerColour
                itemPriceColour
                itemTitleColour
                sectionAlignment
                sectionDisclaimerColour
                sectionBorderColour
                sectionBackgroundColour
                sectionDisclaimer
                sectionDisclaimerLine1
                sectionDisclaimerLine1_noTx: sectionDisclaimerLine1
                sectionDisclaimerLine2
                sectionDisclaimerLine2_noTx: sectionDisclaimerLine2
                spotlightBorderColour
                sectionItems {
                  fieldGroupName
                  itemDisclaimer
                  itemDisclaimer_noTx: itemDisclaimer
                  itemLine1
                  itemLine1_noTx: itemLine1
                  itemLine2
                  itemLine2_noTx: itemLine2
                  itemPrice
                  itemPrice_noTx: itemPrice
                  itemPrices {
                    itemPrice
                    itemPrice_noTx: itemPrice
                  }
                  highlightItem
                }
                sectionLayout
                sectionSubtitle
                sectionSubtitle_noTx: sectionSubtitle
                sectionTitle
                sectionTitle_noTx: sectionTitle
              }
              fieldGroupName
              tabTitle
              tabTitle_noTx: tabTitle
            }
          }
        }
      }
    }
  }
`;

export const WPPageQuery = gql`
  query WordpressPageInfo($id: ID!, $idType: PageIdType!, $asPreview: Boolean, $language: String) {
    allSettings {
      generalSettingsUrl
      generalSettingsTitle
      generalSettingsDescription
    }
    page(id: $id, idType: $idType, asPreview: $asPreview) {
      ...PageFields
    }
    ...WPSettings
    ...NavFragment
    ...AllWPPages
  }
  ${PageFieldsFrag}
  ${WPThemeSettingsFragment}
  ${NavFragment}
  ${AllWPPages}
`;
